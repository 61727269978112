import './App.css';
import React, {useEffect, useState} from 'react';
import Loader from "./components/Loader";
import GradientContainer from "./components/GradientContainer";
import NotFound from "./components/NotFound";
import CardContainer from "./components/CardContainer";
import {getProject} from "./http/project";
import cheerio from 'cheerio';

const App = () => {
    const [loader, setLoader] = useState({status: true, message: 'Loading...'});
    const [project, setProject] = useState({
        name: '',
        subdomain: '',
        image: '',
        description: '',
        links: []
    });

    useEffect(() => {
        setLoader({status: true, message: 'Loading...'})
        getProject(window.location.hostname.split('.').slice(0, -2).join('.'))
            .then((data) => {
                if (data.customCode) {
                    const $ = cheerio.load(data.customCode);
                    const scripts = $('script').map((_, script) => script).get();
                    const noscripts = $('noscript').map((_, noscript) => noscript).get();

                    scripts.forEach(script => {
                        const scriptNode = document.createElement('script');
                        scriptNode.innerHTML = script.children[0].data;
                        document.head.appendChild(scriptNode);
                    });

                    noscripts.forEach(noscript => {
                        const noscriptNode = document.createElement('noscript');
                        noscriptNode.innerHTML = noscript.children[0].data;
                        document.head.appendChild(noscriptNode);
                    });
                }
                setProject({...data});
            })
            .catch(console.error)
            .finally(() => setLoader(null));
    }, []);

    return (
        <GradientContainer>
            {
                (loader === null) && <CardContainer project={project}/>
            }
            {
                loader !== null && <Loader loader={loader}/>
            }
        </GradientContainer>
    );
};

export default App;