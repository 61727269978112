import React from 'react';
import { Container } from 'react-bootstrap';

function getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

function getRandomGradient() {
    const color1 = getRandomColor();
    const color2 = getRandomColor();
    return `linear-gradient(135deg, ${color1}, ${color2})`;
}

const GradientContainer = ({ children }) => {
    const gradient = getRandomGradient();

    const containerStyle = {
        background: gradient,
        margin: 0,
        minHeight: '100vh',
        minWidth: '100vw'
    };

    return (
        <Container style={containerStyle}>
            {children}
        </Container>
    );
};

export default GradientContainer;