import React from 'react';
import {Container, Row, Col, Button} from 'react-bootstrap';

const NotFound = () => {
    return (
        <Container className="d-flex align-items-center justify-content-center">
            <Row>
                <Col className="text-center">
                    <h1 className="display-1">404</h1>
                    <p className="lead">Page Not Found</p>
                    <p>The page you are looking for might have been removed, had its name changed, or is temporarily
                        unavailable.</p>
                </Col>
            </Row>
        </Container>
    );
};

export default NotFound;
