import React, {useEffect} from 'react';
import {Button, Card, Image, Col, Container, Row} from "react-bootstrap";
import NotFound from "./NotFound";

const CardContainer = ({project}) => {
    useEffect(() => {
        document.title = project?.id ? project.name : '404'
    }, []);

    return (
        <div style={{
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <Container>
                <Row className="justify-content-center">
                    <Col md={3}>
                        <Card className={'shadow'}
                              style={{
                                  paddingTop: 40,
                                  paddingBottom: 40,
                                  paddingLeft: 20,
                                  paddingRight: 20,
                                  backgroundColor: 'rgba(255,255,255,.9)'
                              }}>

                            {project.id ?
                                <>
                                    <div className={'d-flex justify-content-center'}>
                                        <Image src={process.env.REACT_APP_API_PUBLIC_URL + project.image}
                                               style={{width: 100, height: 100}} fluid roundedCircle/>
                                    </div>

                                    <Card.Body>
                                        <Card.Title className={'text-center mt-4 mb-3'}>{project.name}</Card.Title>
                                        <Card.Text className={'text-center mb-5'}>{project.description}</Card.Text>
                                        <div className={'d-flex flex-column align-items-center'}>
                                            {project.links.map(el => (
                                                <a style={{borderRadius: 10}} className={'shadow w-100 mb-2'}
                                                   href={el.url}
                                                   target={"_blank"}>
                                                    <Button
                                                        style={{
                                                            backgroundColor: el.bgColor,
                                                            color: el.textColor,
                                                            border: 0,
                                                            transition: 'transform 0.3s'
                                                        }}
                                                        className={'w-100 fw-semibold'}
                                                        onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.03)'}
                                                        onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
                                                    >
                                                        <>{el.title}</>
                                                    </Button>
                                                </a>
                                            ))}
                                        </div>
                                    </Card.Body>
                                </> :
                                <NotFound/>
                            }
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default CardContainer;